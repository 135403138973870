import React, { useState, useEffect } from 'react';
import api from '../api';
import './Most.css';
import Loading from './Loading';

function Most({ handleSubmitSearch }) {
  const [mostMovers, setMostMovers] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMostMovers = async () => {
      try {
        const response = await api.get('/market_movers');
        if (response.data.error) {
          setError(response.data.error);
        } else {
          if (response.data.movers) {
            setMostMovers(response.data.movers);
            setLastUpdated(response.data.lastUpdated);
          } else {
            setError('Failed to fetch market movers');
          }
        }
      } catch (err) {
        setError('Failed to fetch market movers');
        console.error('Error fetching market movers:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMostMovers();
  }, []);

  const handleCardClick = (ticker) => {
    handleSubmitSearch(ticker);
  };

  return (
    <div className="most-container">
      <div className="most-header">
        <h2 className="most-heading">S&P 500 Market Movers</h2>
        {lastUpdated && (
          <span className="last-updated">
            Last Updated: {lastUpdated}
          </span>
        )}
      </div>
      {isLoading && (
        <Loading text={`Fetching market movers`} />
      )}
      {error && <div>{error}</div>}
      {mostMovers && mostMovers.length > 0 && (
        <div className="movers-grid">
          {mostMovers.map((stock, index) => (
            <div 
              key={stock.ticker} 
              className="mover-card fade-in"
              onClick={() => handleCardClick(stock.ticker)}
              style={{ 
                cursor: 'pointer',
                animationDelay: `${index * 100}ms` 
              }}
            >
              <div className="mover-info">
                <div className="mover-ticker">{stock.ticker}</div>
                <div className="mover-price">${stock.price.toFixed(2)}</div>
              </div>
              <div className={`mover-change ${stock.percent_change >= 0 ? 'positive' : 'negative'}`}>
                {stock.percent_change >= 0 ? '▲' : '▼'} {Math.abs(stock.percent_change).toFixed(2)}%
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Most; 