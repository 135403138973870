// frontend/src/Footer.js
import React from 'react'; 
import './Footer.css';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer({ isAuthenticated, onLogout, username }) {
  return (
    <footer className="app-footer">
      <p>The information provided is for educational purposes only and should not be considered financial advice.</p>
      <div className="footer-icons">
        <a 
          href="https://github.com/liunicholas" 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="GitHub"
        >
          <FontAwesomeIcon icon={faGithub} className="footer-icon" />
        </a>
        <a 
          href="mailto:nxliu@mit.edu"
          aria-label="Email"
        >
          <FontAwesomeIcon icon={faEnvelope} className="footer-icon" />
        </a>
        {isAuthenticated && (
          <button 
            className={`footer-logout-button ${username === 'test' ? 'footer-login-button' : ''}`}
            onClick={onLogout}
          >
            {username === 'test' ? 'Login' : 'Logout'}
          </button>
        )}
      </div>
    </footer>
  );
}

export default Footer;