import React, { useState, useEffect } from 'react';
import api from '../api';
import './MarketIndices.css';

function MarketIndices({ setIsMarketIndicesLoading }) {
  const [marketIndices, setMarketIndices] = useState({
    "^DJI": { name: "Dow Jones", price: null, change: null, percentChange: null },
    "^GSPC": { name: "S&P 500", price: null, change: null, percentChange: null },
    "^IXIC": { name: "NASDAQ", price: null, change: null, percentChange: null },
    "^RUT": { name: "Russell 2000", price: null, change: null, percentChange: null }
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchMarketIndices = async () => {
      setIsLoading(true);
      setIsMarketIndicesLoading(true);
      try {
        const response = await api.post('/market_indices');
        setMarketIndices(response.data);
      } catch (err) {
        console.error('Failed to fetch market indices:', err);
      } finally {
        setIsLoading(false);
        setIsMarketIndicesLoading(false);
      }
    };

    fetchMarketIndices();
  }, [setIsMarketIndicesLoading]);

  return (
    <div className="market-indices-container">
      <h2 className="market-indices-heading">Market Indices</h2>
      {!isLoading && (
        <div className="indices-grid">
          {Object.entries(marketIndices).map(([symbol, data], index) => (
            <div 
              key={symbol} 
              className="index-card market-indices-section"
              style={{ '--delay': `${index * 0.1}s` }}
            >
              <h3>{data.name}</h3>
              <div className="price-main">
                <span className="current-price-index">
                  ${data.price?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </span>
                <span className={`price-change-index ${data.change >= 0 ? 'positive' : 'negative'}`}>
                  {data.change >= 0 ? '+' : ''}${Math.abs(data.change?.toFixed(2))} 
                  ({data.change >= 0 ? '+' : ''}{data.percentChange?.toFixed(2)}%)
                </span>
              </div>
              <p className="price-timestamp-index">
                Last Updated: {data.timestamp}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MarketIndices; 