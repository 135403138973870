import React, { useState, useEffect } from 'react';
import api from '../api';
import './News.css';

function News({ currentTicker, getCompanyTitle }) {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneralNews, setIsGeneralNews] = useState(true);

  useEffect(() => {
    const fetchNews = async () => {
      setIsLoading(true);
      try {
        // If there's a ticker, fetch company specific news, otherwise fetch market news
        const response = await api.post('/company_news', {
          ticker: currentTicker || 'SPY'
        });
        
        setNews(response.data.articles);
        setIsGeneralNews(!currentTicker);
      } catch (err) {
        console.error('Failed to fetch news:', err);
        setNews([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNews();
  }, [currentTicker]);

  return (
    <aside className="news-sidebar">
      <div className="news-container">
        <h3>{isGeneralNews ? 'Market News' : 'Current News'}</h3>
        {isLoading ? (
          <div className="news-loading">Loading news...</div>
        ) : news.length > 0 ? (
          <ul className="news-list">
            {news
              .sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((article, index) => (
                <li 
                  key={index} 
                  className="news-item"
                  style={{ '--delay': `${index * 0.1}s` }}
                >
                  <a href={article.url} target="_blank" rel="noopener noreferrer">
                    <h4>{article.headline}</h4>
                    {article.source && (
                      <p className="news-source">From {article.source}</p>
                    )}
                    <p className="news-date">
                      {new Date(article.date).toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric'
                      })}
                    </p>
                  </a>
                </li>
            ))}
          </ul>
        ) : (
          <p className="news-empty">
            {currentTicker 
              ? `No recent news found for ${currentTicker}`
              : 'No recent market news found'}
          </p>
        )}
      </div>
    </aside>
  );
}

export default News;