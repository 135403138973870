import React, { useState } from 'react';
import api from '../api';
import MarketIndices from './MarketIndices';
import Most from './Most';
import './Auth.css';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Auth({ onAuthChange, handleSearchFromAuth }) {
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isMarketIndicesLoading, setIsMarketIndicesLoading] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    if (!isLogin && password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const endpoint = isLogin ? '/login' : '/register';
      const response = await api.post(endpoint, {
        username,
        password
      });
      
      if (response.data.message) {
        if (!isLogin) {
          const loginResponse = await api.post('/login', {
            username,
            password
          });
          if (loginResponse.data.message) {
            onAuthChange(loginResponse.data.username);
          }
        } else {
          onAuthChange(response.data.username);
        }
      }
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
    }
  };

  const handleContinueAsGuest = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      const response = await api.post('/login', {username: "test", password: "test"});
      
      if (response.data.message) {
        onAuthChange(response.data.username);
      }
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-page-content">
        <div className="auth-page-left">
          <h1 className="auth-page-title">Market Color</h1>
          <h2 className="auth-page-subtitle">What's On Your Watchlist Today?</h2>
          <MarketIndices setIsMarketIndicesLoading={setIsMarketIndicesLoading} />
          {!isMarketIndicesLoading && (
            <Most handleSubmitSearch={handleSearchFromAuth} />
          )}
        </div>
        <div className="auth-page-right">
          <div className="auth-container">
            <h2>{isLogin ? 'Login' : 'Register'}</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {!isLogin && (
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              )}
              <button type="submit">{isLogin ? 'Login' : 'Register'}</button>
              {error && <div className="error">{error}</div>}
            </form>
            <button 
              className="toggle-auth" 
              onClick={() => setIsLogin(!isLogin)}
            >
              {isLogin ? 'Need an account? Register' : 'Have an account? Login'}
            </button>
            <button 
              className="toggle-auth" 
              onClick={handleContinueAsGuest}
            >
              Continue as Guest
            </button>
            <div className="auth-footer">
              <p className="auth-disclaimer">
                The information provided is for educational purposes only and should not be considered financial advice.
              </p>
              <div className="auth-footer-icons">
                <a 
                  href="https://github.com/liunicholas" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  aria-label="GitHub"
                >
                  <FontAwesomeIcon icon={faGithub} className="footer-icon" />
                </a>
                <a 
                  href="mailto:nxliu@mit.edu"
                  aria-label="Email"
                >
                  <FontAwesomeIcon icon={faEnvelope} className="footer-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Auth; 