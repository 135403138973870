import React, { useRef, useState, useEffect } from 'react';
import './SearchForm.css';

function SearchForm({ 
  isLoading,
  companyList,
  handleSubmitSearch,
  onTickerSelect
}) {
  const [ticker, setTicker] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  
  const dropdownRef = useRef(null);

  // Handle input change and filter companies
  const handleInputChange = (e) => {
    const input = e.target.value.toUpperCase();
    setTicker(input);
    setHighlightedIndex(-1);

    if (input) {
      const filtered = Object.values(companyList).filter(company =>
        company.title.toLowerCase().includes(input.toLowerCase()) ||
        company.ticker.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredCompanies(filtered);
    } else {
      setFilteredCompanies([]);
    }
  };

  // Handle keyboard navigation in the dropdown
  const handleKeyDown = (e) => {
    if (filteredCompanies.length > 0) {
      if (e.key === 'ArrowDown') {
        setHighlightedIndex((prevIndex) => 
          (prevIndex + 1) % filteredCompanies.length
        );
      } else if (e.key === 'ArrowUp') {
        setHighlightedIndex((prevIndex) => 
          (prevIndex - 1 + filteredCompanies.length) % filteredCompanies.length
        );
      } else if (e.key === 'Enter' && highlightedIndex >= 0) {
        handleCompanySelect(filteredCompanies[highlightedIndex].ticker);
      }
    }
  };

  // Effect to handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setFilteredCompanies([]); // Close the dropdown
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle company selection from the dropdown
  const handleCompanySelect = (selectedTicker) => {
    setTicker(selectedTicker.toUpperCase());
    setFilteredCompanies([]);
    setHighlightedIndex(-1);
    onTickerSelect(selectedTicker.toUpperCase());
  };

  const handleFormSubmit = (e) => {
    setFilteredCompanies([]);
    setHighlightedIndex(-1);
    e.preventDefault();
    handleSubmitSearch(ticker);
  };

  return (
    <form className="form" onSubmit={handleFormSubmit}>
      <div className="form-field-container">
        <div className="form-field">
          <input
            id="ticker-input"
            type="text"
            value={ticker}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Start typing to search for a ticker..."
            required
            className="input"
            autoComplete="off"
            disabled={isLoading}
          />
          {filteredCompanies.length > 0 && (
            <ul className="dropdown" ref={dropdownRef}>
              {filteredCompanies.map((company, index) => (
                <li
                  key={company.ticker}
                  className={`dropdown-item ${index === highlightedIndex ? 'highlighted' : ''}`}
                  onClick={() => handleCompanySelect(company.ticker)}
                >
                  {company.title} ({company.ticker})
                </li>
              ))}
            </ul>
          )}
        </div>
        <button 
          type="submit" 
          className="button submit-button"
          disabled={isLoading}
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default SearchForm;