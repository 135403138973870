import React, { useState, useEffect } from 'react';
import api from '../api';
import './AdminDashboard.css';

function AdminDashboard({ onLogout }) {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await api.get('/admin/users');
      setUsers(response.data);
    } catch (err) {
      setError('Failed to fetch users');
      console.error('Error fetching users:', err);
    }
  };

  const handleClearSearches = async () => {
    if (window.confirm('Are you sure you want to clear all searches? This action cannot be undone.')) {
      try {
        await api.post('/admin/clear_searches');
        alert('All searches cleared successfully');
        fetchUsers(); // Refresh user data after clearing searches
      } catch (err) {
        alert('Failed to clear searches: ' + (err.response?.data?.error || 'Unknown error'));
      }
    }
  };

  return (
    <div className="admin-layout">
      <aside className="admin-sidebar">
        <h2>Users</h2>
        {error && <div className="admin-error">{error}</div>}
        <ul className="users-list">
          {users.map((user, index) => (
            <li 
              key={user.id} 
              className="user-item" 
              style={{ animationDelay: `${index * 0.1}s` }} // Set delay based on index
            >
              <div className="user-info">
                <span className="user-name">{user.username}</span>
                <span className="user-id">ID: {user.id}</span>
                <span className="search-count">
                  Searches: {user.search_count}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </aside>
      <main className="admin-main">
        <h1>Admin Dashboard</h1>
        <div className="admin-controls">
          <button 
            className="admin-button danger"
            onClick={handleClearSearches}
          >
            Clear All Searches
          </button>
        </div>
        <button 
          className="admin-logout-button"
          onClick={onLogout}
        >
          Logout
        </button>
      </main>
    </div>
  );
}

export default AdminDashboard; 