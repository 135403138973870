import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import './CompanyAnalysis.css';
import Loading from './Loading';

function CompanyAnalysis({ companyAnalysis, currentTicker, getCompanyTitle, regenerateInfo, isLoading }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (!currentTicker) return null;

  return (
    <div className="info-container">
      <div className="heading-container">
        <h2 className="analysis-heading">
          {getCompanyTitle()} ({currentTicker.toUpperCase()}) - Research Analysis
        </h2>
        <button 
          className={`collapse-button ${isCollapsed ? 'collapsed' : ''}`}
          onClick={() => setIsCollapsed(!isCollapsed)}
          aria-label={isCollapsed ? 'Expand' : 'Collapse'}
        >
          <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              d="M6 9L12 15L18 9" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className={`collapsible-content ${isCollapsed ? 'collapsed' : ''}`}>
        {isLoading ? (
          <Loading 
            text={`Analyzing ${getCompanyTitle()} (${currentTicker})`} 
          />
        ) : (
          <>
            <ReactMarkdown 
              className="info-text"
              components={{
                h2: ({node, ...props}) => (
                  <h2 className="section-heading" {...props} />
                ),
                ul: ({node, ...props}) => (
                  <ul className="section-list" {...props} />
                ),
                li: ({node, ...props}) => (
                  <li className="section-item" {...props} />
                ),
                p: ({node, ...props}) => (
                  <p className="section-paragraph" {...props} />
                ),
                strong: ({node, ...props}) => (
                  <strong className="section-strong" {...props} />
                )
              }}
            >
              {companyAnalysis}
            </ReactMarkdown>
            <button className="button regenerate-button" onClick={regenerateInfo}>
              Regenerate
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default CompanyAnalysis; 