import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  },
  crossDomain: true,
  credentials: 'include'
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      console.log('Unauthorized - redirecting to login');
    }
    return Promise.reject(error);
  }
);

export default api; 