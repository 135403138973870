import React from 'react';
import './Loading.css';

function Loading({ text }) {
  return (
    <div className="loading-container">
      <p className="loading-text">
        {text}
        <span className="loading-dots">
          <span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </span>
      </p>
    </div>
  );
}

export default Loading; 