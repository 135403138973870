import React, { useState, useEffect } from 'react';
import api from '../api';
import './Fundamentals.css';

function Fundamentals({ currentTicker, getCompanyTitle, setIsFundamentalsLoading }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [fundamentals, setFundamentals] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const fetchFundamentals = async () => {
      setIsLoading(true);
      setIsFundamentalsLoading(true);
      if (!currentTicker) return;
      
      try {
        const fundamentalsResponse = await api.post('/company_fundamentals', { ticker: currentTicker });
        setFundamentals(fundamentalsResponse.data);
      } catch (err) {
        console.error('Failed to fetch fundamentals:', err);
      } finally {
        setIsLoading(false);
        setIsFundamentalsLoading(false);
      }
    };

    fetchFundamentals();
  }, [currentTicker, setIsFundamentalsLoading]);

  if (!fundamentals) return null;

  const animationKey = isCollapsed ? 'collapsed' : 'expanded';

  return (
    <div className="fundamentals-container">
      <div className="heading-container">
        <h2 className="fundamentals-heading">
          {getCompanyTitle()} ({currentTicker.toUpperCase()}) - Key Fundamentals
        </h2>
        <button 
          className={`collapse-button ${isCollapsed ? 'collapsed' : ''}`}
          onClick={() => setIsCollapsed(!isCollapsed)}
          aria-label={isCollapsed ? 'Expand' : 'Collapse'}
        >
          <svg 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            xmlns="http://www.w3.org/2000/svg"
          >
            <path 
              d="M6 9L12 15L18 9" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      {!isLoading && fundamentals && (
      <div className={`collapsible-content ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="fundamentals-grid" key={animationKey}>
          <div className="fundamentals-section">
            <div className="price-main">
              <span className="current-price">
                ${fundamentals.live_data.current_price.toFixed(2)}
              </span>
              <span className={`price-change ${fundamentals.live_data.price_change >= 0 ? 'positive' : 'negative'}`}>
                {fundamentals.live_data.price_change >= 0 ? '+' : ''}
                ${Math.abs(fundamentals.live_data.price_change).toFixed(2)} 
                ({fundamentals.live_data.price_change >= 0 ? '+' : ''}
                {fundamentals.live_data.percent_change.toFixed(2)}%)
              </span>
            </div>
            <p className="price-timestamp">
              Last Updated: {fundamentals.live_data.timestamp}
            </p>
          </div>

          <div className="fundamentals-section">
            <h3>Company Information</h3>
            <p><strong>Industry:</strong> {fundamentals.company_info.industry || 'N/A'}</p>
            <p><strong>Sector:</strong> {fundamentals.company_info.sector || 'N/A'}</p>
            <p className="website-link"><strong>Website:</strong> {
              fundamentals.company_info.website ? 
              <a href={fundamentals.company_info.website} target="_blank" rel="noopener noreferrer">
                {fundamentals.company_info.website}
              </a> : 
              'N/A'
            }</p>
          </div>

          <div className="fundamentals-section">
            <h3>Key Statistics</h3>
            <p><strong>Market Cap:</strong> ${(fundamentals.key_stats.market_cap / 1e9)?.toFixed(2) || 'N/A'}B</p>
            <p><strong>P/E Ratio:</strong> {fundamentals.key_stats.pe_ratio?.toFixed(2) || 'N/A'}</p>
            <p><strong>Forward P/E:</strong> {fundamentals.key_stats.forward_pe?.toFixed(2) || 'N/A'}</p>
            <p><strong>Price to Book:</strong> {fundamentals.key_stats.price_to_book?.toFixed(2) || 'N/A'}</p>
            <p><strong>Dividend Yield:</strong> {fundamentals.key_stats.dividend_yield?.toFixed(2) || 'N/A'}%</p>
          </div>

          <div className="fundamentals-section">
            <h3>Financial Metrics</h3>
            <p><strong>Revenue:</strong> ${(fundamentals.financial_metrics.revenue / 1e9)?.toFixed(2) || 'N/A'}B</p>
            <p><strong>Profit Margin:</strong> {fundamentals.financial_metrics.profit_margin?.toFixed(2) || 'N/A'}%</p>
            <p><strong>Operating Margin:</strong> {fundamentals.financial_metrics.operating_margin?.toFixed(2) || 'N/A'}%</p>
            <p><strong>ROE:</strong> {fundamentals.financial_metrics.return_on_equity?.toFixed(2) || 'N/A'}%</p>
            <p><strong>ROA:</strong> {fundamentals.financial_metrics.return_on_assets?.toFixed(2) || 'N/A'}%</p>
          </div>

          <div className="fundamentals-section">
            <h3>Trading Information</h3>
            <p><strong>Current Price:</strong> ${fundamentals.trading_info.current_price?.toFixed(2) || 'N/A'}</p>
            <p><strong>52-Week Range:</strong> ${fundamentals.trading_info.fifty_two_week_low?.toFixed(2) || 'N/A'} - ${fundamentals.trading_info.fifty_two_week_high?.toFixed(2) || 'N/A'}</p>
            <p><strong>Avg Volume:</strong> {(fundamentals.trading_info.avg_volume / 1e6)?.toFixed(2) || 'N/A'}M</p>
            <p><strong>Short Ratio:</strong> {fundamentals.trading_info.short_ratio?.toFixed(2) || 'N/A'} days</p>
            <p><strong>Short % of Float:</strong> {fundamentals.trading_info.short_percent_float?.toFixed(2) || 'N/A'}%</p>
          </div>

          <div className="fundamentals-section">
            <h3>Analyst Opinions</h3>
            <p><strong>Recommendation:</strong> {fundamentals.analyst_opinions.recommendation?.toUpperCase() || 'N/A'}</p>
            <p><strong>Mean Target:</strong> ${fundamentals.analyst_opinions.target_mean_price?.toFixed(2) || 'N/A'}</p>
            <p><strong>High Target:</strong> ${fundamentals.analyst_opinions.target_high_price?.toFixed(2) || 'N/A'}</p>
            <p><strong>Low Target:</strong> ${fundamentals.analyst_opinions.target_low_price?.toFixed(2) || 'N/A'}</p>
            <p><strong>Median Target:</strong> ${fundamentals.analyst_opinions.target_median_price?.toFixed(2) || 'N/A'}</p>
            <p><strong>Number of Analysts:</strong> {fundamentals.analyst_opinions.number_of_analysts || 'N/A'}</p>
          </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Fundamentals; 