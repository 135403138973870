import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import './Thesis.css';
import Loading from './Loading';

function Thesis({ thesisData, currentTicker, getCompanyTitle, isThesisLoading, generateThesis, regenerateThesis }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [activeTab, setActiveTab] = useState('final');

  useEffect(() => {
    if (thesisData || isThesisLoading) {
      setIsCollapsed(false);
    }
  }, [thesisData, isThesisLoading]);

  if (!currentTicker) return null;

  return (
    <div className="info-container">
      <div className="heading-container">
        <h2 className="analysis-heading">
          {getCompanyTitle()} ({currentTicker.toUpperCase()}) - Investment Thesis
        </h2>
        <div className="button-container">
          <button 
            className="button regenerate-button"
            onClick={() => generateThesis(currentTicker)}
            disabled={isThesisLoading}
          >
            Generate Thesis
          </button>
          <button 
            className={`collapse-button ${isCollapsed ? 'collapsed' : ''}`}
            onClick={() => setIsCollapsed(!isCollapsed)}
            aria-label={isCollapsed ? 'Expand' : 'Collapse'}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
      <div className={`collapsible-content ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="thesis-tabs">
          <button 
            className={`tab ${activeTab === 'final' ? 'active' : ''}`}
            onClick={() => setActiveTab('final')}
            disabled={isThesisLoading}
          >
            Final Thesis
          </button>
          <button 
            className={`tab ${activeTab === 'industry' ? 'active' : ''}`}
            onClick={() => setActiveTab('industry')}
            disabled={isThesisLoading}
          >
            Industry
          </button>
          <button 
            className={`tab ${activeTab === 'financial' ? 'active' : ''}`}
            onClick={() => setActiveTab('financial')}
            disabled={isThesisLoading}
          >
            Financial
          </button>
          <button 
            className={`tab ${activeTab === 'news' ? 'active' : ''}`}
            onClick={() => setActiveTab('news')}
            disabled={isThesisLoading}
          >
            News
          </button>
        </div>

        {isThesisLoading ? (
          <Loading text={`Generating thesis for ${currentTicker}`} />
        ) : thesisData && (
          <div className="thesis-content">
            <ReactMarkdown
              className="info-text"
              components={{
                h2: ({node, ...props}) => (
                  <h2 className="section-heading" {...props} />
                ),
                ul: ({node, ...props}) => (
                  <ul className="section-list" {...props} />
                ),
                li: ({node, ...props}) => (
                  <li className="section-item" {...props} />
                ),
                p: ({node, ...props}) => (
                  <p className="section-paragraph" {...props} />
                ),
                strong: ({node, ...props}) => (
                  <strong className="section-strong" {...props} />
                )
              }}
            >
              {activeTab === 'final' ? thesisData.final_thesis :
               activeTab === 'industry' ? thesisData.industry_analysis :
               activeTab === 'financial' ? thesisData.financial_analysis :
               thesisData.news_analysis}
            </ReactMarkdown>
            <div className="bottom-button-container">
              <button 
                className="button regenerate-button"
                onClick={() => regenerateThesis(currentTicker)}
                disabled={isThesisLoading}
              >
                Regenerate Thesis
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Thesis; 