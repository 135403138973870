import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';

function Sidebar({ searches, handleSearchClick, handleDelete, clearSearch, isLoading }) {
  return (
    <aside className="sidebar">
      <button className="sidebar-title-button" onClick={clearSearch} disabled={isLoading}>
        Market Color
      </button>
      <h3 className="sidebar-subtitle">Previous Searches</h3>
      <ul className="search-list">
        {searches.map((search, index) => (
          <li
            key={search.ticker}
            className="search-item"
            style={{ animationDelay: `${index * 0.05}s` }}
          >
            <button
              className="search-button"
              onClick={() => handleSearchClick(search.ticker)}
              disabled={isLoading}
            >
              <div className="search-content">
                {search.ticker}
                <span className="search-date">
                  {new Date(search.search_date).toLocaleDateString(undefined, {
                    month: 'short',
                    day: 'numeric'
                  })}
                </span>
              </div>
              <button
                className="delete-button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(search.ticker);
                }}
                disabled={isLoading}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default Sidebar; 